"use client";
import { LoadingOverlay } from "@mantine/core";

export default function Loading() {
  return (
    <LoadingOverlay
      loaderProps={{ variant: "dots" }}
      visible
      overlayProps={{ blur: 2 }}
    />
  );
}
